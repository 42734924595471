import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ModalMacroAcaoForm from "./ModalMacroAcaoForm";
import { Theme } from "app/shared/utils";
import { useDate } from "app/shared/hooks";
import { formatMoney } from "app/shared/helpers";

const MacroAcaoInfo = ({ macroAcao, onChange }) => {
  const [openModal, setOpenModal] = useState(false);
  const { formatDate  } = useDate()

  const Info = ({ title, value }) => {
    return (
      <Grid item sm={6} xs={12} mb="10px">
        <Typography fontWeight="bold" variant="body1" color={Theme.colors.gray.ligth}>
          {title.toUpperCase()}
        </Typography>
        <Typography variant="body1">{value || "Não informado"}</Typography>
      </Grid>
    );
  };

  const onSaveMacroAcao = (value) => {
    onChange(value);
    setOpenModal(false);
  }


  return (
    <>
      <Grid container>
        <Info title={"Início"} value={macroAcao.inicio ? formatDate(new Date(macroAcao.inicio).setUTCHours(3)) : undefined } />
        <Info title={"Termino"} value={macroAcao.termino ?formatDate(new Date(macroAcao.termino).setUTCHours(3)) : undefined} />
        <Info title={"Responsável"} value={macroAcao.responsavel} />
        <Info title={"Financiado"} value={macroAcao.financiador} />
        <Info title={"Valor total"} value={formatMoney(macroAcao.valor_total)} />
        <Info title={"Descricao"} value={macroAcao.descricao} />
      </Grid>
      <Button
        style={{
          width: "fit-content",
          marginBottom: 10,
        }}
        onClick={() => setOpenModal(true)}
        variant="outlined"
        size="small"
      >
        Editar macro ação
      </Button>
      {openModal && (
        <ModalMacroAcaoForm
          item={macroAcao}
          onClose={() => setOpenModal(false)}
          onSave={onSaveMacroAcao}
          open={openModal}
        />
      )}
    </>
  );
};

export default MacroAcaoInfo;
