import { getApiUrl } from "../helpers";

const mobiUrl = getApiUrl(
  "https://apiv3.mobieduca.me/",
  "https://apiv3.hmg-mobieduca.com.br/"
);
const paarUrl = getApiUrl(
  "https://paar-apiv4.mobieduca.me/",
  "https://paar-apiv4.hmg-mobieduca.com.br"
);

export const DOMAIN = mobiUrl;
export const DOMAIN_PAAR = paarUrl;

export const LOGIN = DOMAIN + "login/run";
