import {
  Button,
  Grid,
  Hidden,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Theme } from "app/shared/utils";
import { AnexosSection, ModalHistorico } from "./components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRole, useService, useUnmount } from "app/shared/hooks";
import { HeaderAnoGlobal, LoaderComponent } from "app/shared/components";
import { buscaRecursoPorId } from "app/services/recurso";
import { useFormat } from "app/shared/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setRecursoSelecionado } from "app/redux/reducers/recursoSlice";
import ChipSituacao from "../components/ChipSituacao";
import { RecursoStatus } from "app/shared/constants";
import MacroAcao from "./components/MacroAcao";
import { formatMoney } from "app/shared/helpers";

const RecursoDetalhesPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isRede, isSup, isEscola } = useRole();
  const recursoSelecionado = useSelector(
    (state) => state.recurso.recursoSelecionado
  );
  const filtros = useSelector((state) => state.global);
  const { formataValorParaReais } = useFormat();
  const [historicoOpen, setHistoricoOpen] = useState(false);
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const navigate = useNavigate();

  const handleLoadRecursos = (data) => {
    if (data) {
      const valor_total = data.macro_acao_escolas.reduce((value, current) => {
        return value + (current.valor_itens ? Number(current.valor_itens) : 0)
      },0)
      dispatch(setRecursoSelecionado({...data, valor_total: formatMoney(valor_total)}));
    }
  };

  useUnmount(() => {
    dispatch(setRecursoSelecionado(null));
  });

  const {
    request: getRecursoPorIdReq,
    response,
    retry: retryBuscaRecursoPorId,
  } = useService(buscaRecursoPorId, false, handleLoadRecursos);


  const loadRecurso = () => {
    getRecursoPorIdReq({
      id: id,
      with: "parcela,escola.superintendencia,anexos,macro_acao_escolas.itens.situacao,macro_acao_escolas.macro_acao,macro_acao_escolas.itens.anexos",
    });
  } 

  useEffect(() => {
    if (id) {
      loadRecurso()
    }
  }, [id]);

  const handleShowHistorico = () => {
    setHistoricoOpen(true);
  };

  const handleEditarRecurso = () => {
    const planoLabel = recursoSelecionado.tipo_plano.descricao;
    const parcelaId = recursoSelecionado.parcela_id;
    const parcelaLabel = recursoSelecionado.parcela.parcela;
    const planoId = recursoSelecionado.id;
    navigate(
      `/recurso/form?planoLabel=${planoLabel}&parcelaLabel=${parcelaLabel}&parcelaId=${parcelaId}&id=${planoId}`
    );
  };
  const parcelaStatus = response?.data?.parcela?.status || 0;
  const parcelaBloqueada = parcelaStatus === 0;


  return (
    <>
      <LoaderComponent
        {...response}
        retry={retryBuscaRecursoPorId}
        message="Buscando recurso"
        errorMessage="Falha ao carregar recurso"
      />

      {recursoSelecionado && !response?.loading && (
        <Grid>
          <HeaderAnoGlobal
            hasBackButton={true}
            showAnoSelect={false}
            titulo="Detalhes do plano"
          />
          <Grid
            mt="20px"
            padding="20px 20px 5px"
            container
            flexDirection="column"
            position="relative"
            component={Paper}
          >
            <Typography
              fontWeight="bold"
              variant="h5"
              color={Theme.colors.blue.dark}
            >
              {recursoSelecionado.tipo_plano.descricao}
            </Typography>

            <Grid>
              {isRede && 
                <Typography
                  variant="body1"
                  color={Theme.colors.gray.ligth}
                  pr={1}
                >
                  <span style={{ fontWeight: "bold" }}>GRE:</span>{" "}
                  {recursoSelecionado.escola.superintendencia.nome}
                </Typography>
              } 
              {(isRede || isSup) && 
                <Typography
                  variant="body1"
                  color={Theme.colors.gray.ligth}
                  pr={1}
                >
                  <span style={{ fontWeight: "bold" }}>ESCOLA:</span>{" "}
                  {recursoSelecionado.escola.nome}
                </Typography>
              }
              
              <Typography
                variant="body1"
                color={Theme.colors.gray.ligth}
                pr={1}
              >
                <span style={{ fontWeight: "bold" }}>PARCELA:</span>{" "}
                {recursoSelecionado.parcela.parcela}
              </Typography>
              <Typography variant="body1" color={Theme.colors.gray.ligth}>
                <span style={{ fontWeight: "bold" }}>ANO:</span> {filtros.ano}
              </Typography>
              <ChipSituacao status={recursoSelecionado.status} />
            </Grid>

            <Hidden smDown>
              <Grid position="absolute" right="10px">
                <Grid container>
                  <Grid pr={1}>
                    <Button
                      onClick={handleShowHistorico}
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#7b1fa2" }}
                    >
                      Histórico
                    </Button>
                  </Grid>
                  <Grid>
                    {isEscola &&
                      recursoSelecionado.status !== RecursoStatus.DEFERIDO && (
                        <Tooltip
                          title={
                            parcelaBloqueada ? "Parcela bloqueada!" : undefined
                          }
                        >
                          <span>
                            <Button
                              fullWidth
                              onClick={handleEditarRecurso}
                              disabled={parcelaBloqueada}
                              variant="contained"
                              size="small"
                              color="primary"
                            >
                              Editar
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid my="20px" width="100%">
                <Grid container>
                  <Grid pr={1}>
                    <Button
                      onClick={handleShowHistorico}
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#7b1fa2" }}
                    >
                      Histórico
                    </Button>
                  </Grid>
                  <Grid>
                    {!isRede &&
                      recursoSelecionado.status !== RecursoStatus.DEFERIDO && (
                        <Tooltip
                          title={
                            parcelaBloqueada ? "Parcela bloqueada!" : undefined
                          }
                        >
                          <span>
                            <Button
                              fullWidth
                              onClick={handleEditarRecurso}
                              disabled={parcelaBloqueada}
                              variant="contained"
                              size="small"
                              color="primary"
                            >
                              Editar
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>

          <Grid
            mt="10px"
            padding="20px 20px 5px"
            container
            direction="column"
            position="relative"
            component={Paper}
          >
            <Grid item mb="10px">
              <Typography
                fontWeight="bold"
                variant="body1"
                color={Theme.colors.gray.ligth}
              >
                OBJETIVO ESTRATÉGICO
              </Typography>
              <Typography variant="body1">
                {recursoSelecionado.objetivo_estrategico}
              </Typography>
            </Grid>
            <Grid item mb="10px">
              <Typography
                fontWeight="bold"
                variant="body1"
                color={Theme.colors.gray.ligth}
              >
                META
              </Typography>
              <Typography variant="body1">{recursoSelecionado.meta}</Typography>
            </Grid>
            <Grid item mb="10px">
              <Typography
                fontWeight="bold"
                variant="body1"
                color={Theme.colors.gray.ligth}
              >
                JUSTIFICATIVA
              </Typography>
              <Typography variant="body1">
                {recursoSelecionado.justificativa}
              </Typography>
            </Grid>
            <Grid item mb="10px">
              <Typography
                fontWeight="bold"
                variant="body1"
                color={Theme.colors.gray.ligth}
              >
                VALOR TOTAL DO PLANO
              </Typography>
              <Typography variant="body1">
                {formataValorParaReais(recursoSelecionado.valor_total)}
              </Typography>
            </Grid>
            <MacroAcao onRefresh={loadRecurso}  />
            <AnexosSection anexos={recursoSelecionado.anexos} />
          </Grid>
          {historicoOpen && (
            <ModalHistorico
              open={historicoOpen}
              onClose={() => setHistoricoOpen(false)}
              paramsId={{
                "item[plano_id]": recursoSelecionado.id,
              }}
            />
          )}
        </Grid>
      )}
    </>
  );
};
export default RecursoDetalhesPage;
