import { Box, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import {
  setItemRecursoSelecionado,
  setRecursoSelecionado,
} from "app/redux/reducers/recursoSlice";
import { alteraStatusRecursoItem } from "app/services/recurso/post";
import { GenericModal } from "app/shared/components";
import { RecursoItemStatus } from "app/shared/constants";
import { useService } from "app/shared/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import ChipSituacao from "../ChipSituacao";

const ModalAnalisarItem = ({ isOpen, onClose, onRefresh }) => {
  const dispatch = useDispatch();
  const { itemRecursoSelecionado, recursoSelecionado } = useSelector((state) => state.recurso);

  const [situacao, setSituacao] = useState(null);
  const [motivo, setMotivo] = useState();

  const handleAlteraSituacao = (data) => {
    if (data) {
      toastr.success("Sucesso ao alterar a situação do item");
      onClose();
      onRefresh()
    }
  };

  const {
    request: alteraSituacaoReq,
    response: { loading },
  } = useService(alteraStatusRecursoItem, false, handleAlteraSituacao);

  const handleOnSave = () => {
    if (!situacao) return toastr.warning("Uma nova situação deve ser escolhida!");
    alteraSituacaoReq({
      descricao: motivo,
      item_id: itemRecursoSelecionado.id,
      status: situacao,
    });
  };

  useEffect(() => {
    return () => {
      dispatch(setItemRecursoSelecionado(null));
    };
  }, []);

  const statusList = Object.keys(RecursoItemStatus).filter((status) => {
    return RecursoItemStatus[status] !== itemRecursoSelecionado.situacao.status;
  });

  return (
    <GenericModal
      open={isOpen}
      onClose={onClose}
      size="xs"
      onSave={handleOnSave}
      isLoading={loading}
      preventCloseClickOutside
    >
      <Grid container>
        <Box width="100%">
          <Typography mr="10px" variant="h6" color="#59829d">
            ITEM {itemRecursoSelecionado.id}
          </Typography>

          <Divider style={{ marginBottom: 10, marginTop: 5 }} />

          <Box display="flex" flexDirection="row" width="fit-content" alignItems="baseline">
            <Typography mr="10px" variant="button" color="#59829d">
              SITUAÇÃO ATUAL:
            </Typography>
            <ChipSituacao size="small" status={itemRecursoSelecionado?.situacao?.status} />
          </Box>

          <FormControl fullWidth>
            <Typography mt="10px" variant="button" color="#59829d" mb={1}>
              NOVA SITUAÇÃO DO ITEM*
            </Typography>
            <Select
              id="demo-simple-select-situacao"
              size="small"
              displayEmpty
              value={situacao}
              placeholder="Selecione uma situação para o item"
              onChange={(e) => {
                setSituacao(e.target.value);
              }}
            >
              {statusList.map((status, key) => {
                return (
                  <MenuItem value={RecursoItemStatus[status]} key={RecursoItemStatus[status]}>
                    {status}
                  </MenuItem>
                );
              })}
            </Select>
            <Typography variant="button" color="#59829d" marginTop={2} mb={1}>
              MOTIVO
            </Typography>
            <TextField
              multiline
              minRows={4}
              maxRows={10}
              onChange={(e) => {
                setMotivo(e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </Grid>
    </GenericModal>
  );
};

export default ModalAnalisarItem;
