import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ListaVazia, StyledTableContainer } from "app/shared/components";
import { useState } from "react";
import { FiEdit2, FiTrash } from "react-icons/fi";
import ModalItemForm from "./ModalItemForm";
import { useForm,  } from "react-hook-form";
import { formatMoney } from "app/shared/helpers";
import { FiClock } from "react-icons/fi";
import { RecursoItemStatus } from "app/shared/constants";
import toastr from "toastr";

const ItensForm = ({valorTotal, itens, onChange, macroAcao}) => {

  const {setValue, getValues, watch} = useForm({
    values: {
        itens: itens,
    },
    mode: "all",
});
  
  const [modalItem, setModalItem] = useState({
    open: false,
    item: null,
  });

  const resetModalItem = () => {
    setModalItem({
      open: false,
      item: null,
    });
  };

  const handleOpenModalItem = (selectedItem = null) => {
    setModalItem({
      open: true,
      item: selectedItem,
    });
  };


  const getValorSomaItens = (newItem) => {
      return itens
        .filter(item => item.id !== newItem.id)
        .reduce((count, value) => {
        return count + getTotalPorItem(value)
      },0);
  }

  const validaValorTotal = (newItem) => {
    try{
      const valorSomaItens = getValorSomaItens(newItem);
      const valorAtual = getTotalPorItem(newItem);

      if((valorAtual + valorSomaItens) > Number(valorTotal)){
        return false
      }

      return true
    }catch(e){
      return false
    }
  } 

  const handleChangesItems = (newItem) => {
    if(!validaValorTotal(newItem)){
      toastr.warning(`Valor excedeu o valor total de ${formatMoney(valorTotal)}`)
      return;
    };
    const isEdicaoItem = !!modalItem?.item;
    resetModalItem();
    const itens = getValues("itens");
    let itensAtt = [...itens, newItem]
    if (isEdicaoItem) {
      itensAtt = itens.map((item) => item.id === newItem.id ? { ...item, ...newItem }: item)
    }
    setValue("itens",itensAtt);
    onChange(itensAtt)
  };

  const handleRemoveItem = (itemId) => {
    const itens = getValues("itens");
    const itensAtt =  itens.filter((i) => i.id !== itemId)
    setValue(
      "itens",
      itensAtt
    );
    onChange(itensAtt)
  };

  const getTotalPorItem = (item) => {
    return (
      (Number(item.custo_capital) + Number(item.custo_custeio)) *
      item.quantidade
    );
  };

  const getIdLabel = (item) => {
    if (String(item.id).includes("new")) {
      return (
        <Tooltip
          arrow
          title="Item aguardando gravação do recurso"
          placement="top"
        >
          <span>
            <FiClock size={18} />
          </span>
        </Tooltip>
      );
    }
    return item.id;
  };

  return (
    <Grid container direction="column" mt="20px">
      <Button
        style={{
          width: "fit-content",
          marginBottom: 10,
        }}
        onClick={() => handleOpenModalItem()}
        variant="contained"
        size="small"
      >
        NOVO ITEM
      </Button>
      <StyledTableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>AÇÃO</TableCell>
              <TableCell align="left">CAPITAL (R$)</TableCell>
              <TableCell align="left">CUSTEIO (R$)</TableCell>
              <TableCell align="left">QUANTIDADE</TableCell>
              <TableCell align="left">TOTAL (R$)</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {watch("itens")?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="left">{item.acao}</TableCell>
                  <TableCell align="left">
                    {item.custo_capital ? formatMoney(item.custo_capital) : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {item.custo_custeio ? formatMoney(item.custo_custeio) : "-"}
                  </TableCell>
                  <TableCell align="left">{item.quantidade}</TableCell>
                  <TableCell align="left">
                    {formatMoney(getTotalPorItem(item))}
                  </TableCell>
                  <TableCell align="left">
                    {RecursoItemStatus.DEFERIDO !== item.situacao?.status && (
                      <>
                        <IconButton onClick={() => handleOpenModalItem(item)}>
                          <FiEdit2 size={20} />
                        </IconButton>
                        <IconButton onClick={() => handleRemoveItem(item.id)}>
                          <FiTrash size={20} />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <ListaVazia isListaVazia={getValues("itens")?.length <= 0} />
      </StyledTableContainer>
      {modalItem.open && (
        <ModalItemForm
          open={modalItem.open}
          item={modalItem.item}
          onClose={() => resetModalItem()}
          onSave={handleChangesItems}
          macroAcao={macroAcao}
        />
      )}
    </Grid>
  );
};

export default ItensForm;
