import { Grid, MenuItem, Paper, Select, Typography } from "@mui/material";
import TableSituacao from "../TableSituacao";
import { Theme } from "app/shared/utils";
import { CardInfo, LoaderComponent, Paginacao } from "app/shared/components";
import { useApp, useDebounce, useService } from "app/shared/hooks";
import { buscaPlanoStatusCounters } from "app/services/plano";
import { useEffect, useState } from "react";
import { buscaRecursos } from "app/services/recurso";
import { RecursoStatus } from "app/shared/constants";
import { useSelector } from "react-redux";
import { formatParams } from "app/shared/helpers";

const PAGE_LIMIT = 5;
const ListaPlanosSection = () => {
  const { ano } = useApp();
  const { tiposPlano } = useSelector((state) => state.dashboard);
  const filtros = useSelector((state) => state.global.filtros);
  const [pagina, setPagina] = useState(1);
  const [totalPlanos, setTotalPlanos] = useState(0);
  const [selectedTipo, setSelectedTipo] = useState(-1);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const selectedStatusDebounced = useDebounce(selectedStatus)

  const {
    request: getPlanosByStatus,
    response: responsePlanosByStatus,
    retry: retryPlanosByStatus,
  } = useService(buscaPlanoStatusCounters, false, () => { }, () => { }, { cache: true, cacheKey: "getPlanosByStatus" });

  const counters = (() => {
    const c = responsePlanosByStatus?.data?.data;
    if (!c)
      return {
        pendente: 0,
        analise: 0,
        deferido: 0,
        indeferido: 0,
      };

    return {
      pendente: c.find((s) => s.status + '' === RecursoStatus.PENDENTE + '')?.total || 0,
      analise: c.find((s) => s.status + '' === RecursoStatus.EM_ANALISE + '')?.total || 0,
      deferido: c.find((s) => s.status + '' === RecursoStatus.DEFERIDO + '')?.total || 0,
      indeferido:
        c.find((s) => s.status + '' === RecursoStatus.INDEFERIDO + '')?.total || 0,
    };
  })();

  const handleLoadPlanos = (data) => {
    if (data) {
      setTotalPlanos(data.last_page);
    }
  };

  const {
    request: getPlanos,
    response: responsePlanos,
    retry: retryPlanos,
  } = useService(buscaRecursos, false, handleLoadPlanos, () => { }, { cache: true, cacheKey: "getPlanosRecursos" });

  const getDados = () => {
    const params = {
      escola_id: filtros.escola,
      si_id: filtros.gre,
      "parcela[ano]": ano,
      tipo_plano_id: selectedTipo,
    };
    getPlanosByStatus(formatParams(params));
  };

  const getDataPlanos = () => {
    const params = {
      escola_id: filtros.escola,
      si_id: filtros.gre,
      with: "escola,parcela,superintendencia,tipo_plano",
      "parcela[ano]": ano,
      tipo_plano_id: selectedTipo,
      status: selectedStatus,
      page: pagina,
      limit: PAGE_LIMIT,
    };
    getPlanos(formatParams(params));
  };

  useEffect(() => {
    getDataPlanos();
  }, [ano, selectedStatusDebounced, selectedTipo, filtros, pagina]);

  useEffect(() => {
    setPagina(1);
  }, [selectedTipo, filtros, selectedStatusDebounced]);

  useEffect(() => {
    getDados();
  }, [ano, selectedTipo, filtros]);

  const handleSelectStatusFilter = (status) => {
    if (status === selectedStatus) {
      return setSelectedStatus(null);
    }
    setSelectedStatus(status);
  };

  const total = responsePlanos?.data?.total;

  return (
    <Grid
      container
      flexDirection="column"
      component={Paper}
      padding="20px 20px 5px"
    >
      <Grid item>
        <Typography
          variant="h6"
          fontWeight="bold"
          color={Theme.colors.blue.dark}
        >
          Situação dos planos
        </Typography>
      </Grid>

      <Grid item my="10px">
        <Select
          id="recurso-select"
          labelId="recurso-label"
          value={selectedTipo}
          onChange={(event) => setSelectedTipo(event.target.value)}
          size="small"
          style={{
            width: "100%",
            maxWidth: 200,
          }}
        >
          <MenuItem value={-1}>Selecione Tipo</MenuItem>
          {tiposPlano.map((tipo) => {
            return (
              <MenuItem key={tipo.id} value={tipo.id}>
                {tipo.descricao}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <LoaderComponent
        {...responsePlanosByStatus}
        retry={retryPlanosByStatus}
        message="Carregando indicadores..."
      >
        <Grid container my="10px" spacing={1}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <CardInfo
              label="PENDENTE"
              hasBorder
              isHover={true}
              borderColor={Theme.colors.orange}
              hasElevation={false}
              isSelected={RecursoStatus.PENDENTE === selectedStatus}
              onClick={() => handleSelectStatusFilter(RecursoStatus.PENDENTE)}
            >
              <Typography color={Theme.colors.orange} variant="h5">
                {counters.pendente}
              </Typography>
            </CardInfo>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <CardInfo
              label="EM ANÁLISE"
              hasBorder
              isHover={true}
              borderColor={Theme.colors.blue.dark}
              hasElevation={false}
              isSelected={RecursoStatus.EM_ANALISE === selectedStatus}
              onClick={() => handleSelectStatusFilter(RecursoStatus.EM_ANALISE)}
            >
              <Typography color={Theme.colors.blue.dark} variant="h5">
                {counters.analise}
              </Typography>
            </CardInfo>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <CardInfo
              label="DEFERIDO"
              isHover={true}
              hasBorder
              isSelected={RecursoStatus.DEFERIDO === selectedStatus}
              borderColor={Theme.colors.green}
              hasElevation={false}
              onClick={() => handleSelectStatusFilter(RecursoStatus.DEFERIDO)}
            >
              <Typography color={Theme.colors.green} variant="h5">
                {counters.deferido}
              </Typography>
            </CardInfo>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <CardInfo
              label="INDEFERIDO"
              isHover={true}
              hasBorder
              borderColor={Theme.colors.red}
              hasElevation={false}
              isSelected={RecursoStatus.INDEFERIDO === selectedStatus}
              onClick={() => handleSelectStatusFilter(RecursoStatus.INDEFERIDO)}
            >
              <Typography color={Theme.colors.red} variant="h5">
                {counters.indeferido}
              </Typography>
            </CardInfo>
          </Grid>
        </Grid>
      </LoaderComponent>
      <LoaderComponent
        {...responsePlanos}
        retry={retryPlanos}
        message="Carregando planos"
      />
      {!!total && (
        <Typography my="10px" variant="h6" textAlign="right">
          TOTAL: {total}
        </Typography>
      )}
      {!responsePlanos.loading && (
        <TableSituacao
          isLoading={responsePlanos.loading}
          planos={responsePlanos?.data?.data}
        />
      )}
      <Grid width="100%">
        {totalPlanos > 0 && !responsePlanos.loading && (
          <Paginacao
            setNumeroPagina={setPagina}
            pagina={pagina}
            totalItems={totalPlanos}
            style={{ float: "right", padding: 10 }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ListaPlanosSection;
