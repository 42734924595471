import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { setStatus } from "app/redux/reducers/globalSlice";
import { setMostraSomenteSemPlano } from "app/redux/reducers/recursoSlice";
import FiltroEscola from "app/shared/components/FiltroEscola";
import FiltroGRE from "app/shared/components/FiltroGRE";
import FiltroParcela from "app/shared/components/FiltroParcela";
import FiltroPlano from "app/shared/components/FiltroPlano";
import FiltroStatus from "app/shared/components/FiltroStatus";
import { useRole } from "app/shared/hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const FiltrosRecursos = () => {
  const { isRede, isSup } = useRole();
  const dispatch = useDispatch();
  const mostraSomenteSemPlano = useSelector(
    (state) => state.recurso.mostraSomenteSemPlano
  );

  const tamanhoColunaFiltrosMaiores = isRede || isSup ? 3 : 4;
  const tamanhoColunaFiltrosMenores = isRede ? 2 : isSup ? 3 : 4;

  return (
    <>
      {isRede && 
        <Grid
          item
          lg={tamanhoColunaFiltrosMaiores}
          xs={12}
          mt={2}
          mb={2}
          pr={1}
        >
          <FiltroGRE />
        </Grid>
      }      
      {(isRede||isSup) && 
        <Grid
          item
          lg={tamanhoColunaFiltrosMaiores}
          xs={12}
          mt={2}
          mb={2}
          pr={1}
        >
          <FiltroEscola />
        </Grid>
      }
      <Grid
        item
        lg={tamanhoColunaFiltrosMenores}
        xs={12}
        mt={2}
        mb={2}
        pr={1}
      >
        <FiltroPlano />
      </Grid>
      <Grid item lg={tamanhoColunaFiltrosMenores} xs={12} mt={2} mb={2} pr={1}>
        <FiltroParcela />
      </Grid>
      <Grid item lg={tamanhoColunaFiltrosMenores} mt={2} mb={2} xs={12}>
        <FiltroStatus />
      </Grid>
      <Grid item lg={12} xs={12}>
        <FormControlLabel
          control={<Checkbox />}
          checked={mostraSomenteSemPlano}
          onChange={(e) => {
            dispatch(setStatus(-1))
            dispatch(setMostraSomenteSemPlano(e.target.checked));
          }}
          label="Planos não cadastrados"
        />
      </Grid>
    </>
  );
};

export default FiltrosRecursos;
