export const TokenApi = "fa17b30364e9b0ab8328a584c7a745be";
export const AppKey = "PL23R";
export const TokenApp = "77c9ef64c2e3033a873aba28a05ab4036ca578810602020f293abcd4ea0791e5";
export const ROLES = {
    PERFIL_ESCOLA: 0,
    PERFIL_GESTOR: 1,
    PERFIL_ADMIN: 2,
    PERFIL_SUP: 3,
    PERFIL_ALUNO: 4,
    PERFIL_PROFESSOR: 5,
    PERFIL_TECNICO: 6,
    PERFIL_CIDADE: 10,
};
export const RecursoStatus = {
    PENDENTE: 0,
    EM_ANALISE: 1,
    DEFERIDO: 2,
    INDEFERIDO: 3,
};

export const RecursoItemStatus = {
    CRIADO: 0,
    DEFERIDO: 1,
    INDEFERIDO: 2,
    REENVIADO: 3,
};

export const TipoAnexo = {
    PROPOSTA: 0,
    NOTA_FISCAL: 1,
    FOTOS: 2,
    COMPROVANTE_CREDITO: 3,
};